import { Autocomplete, Chip, CircularProgress, InputAdornment, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Controller, useFormContext } from 'react-hook-form';
import { Close } from '@mui/icons-material';

const RHFSelector = ({
  options = [],
  label,
  require,
  name,
  inputProps,
  InputProps,
  onChange,
  multiple,
  log,
  defaultValue,
  required,
  ...other
}) => {
  const { control } = useFormContext();
  if (log) console.log(`* * * RHFSelector ${label}: `, { options });
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        if (log) console.log('* * * RHFSelector :', { field });
        const availableOptions = options?.filter((option) =>
          multiple
            ? !field?.value?.some((selected) => selected.value === option.value)
            : field.value?.value !== option.value
        );

        return (
          <Autocomplete
            ref={field.ref}
            onBlur={field.onBlur}
            noOptionsText={'دیتایی یافت نشد !'}
            multiple={multiple}
            id={name}
            // freeSolo
            loadingText={'لطفا منتظر بمانید . . .'}
            onChange={(event, newValue) => {
              console.log({ newValue });
              if (multiple) newValue = newValue?.filter((x) => x.label || x.value);
              else newValue = newValue?.label || newValue?.value ? newValue : null;
              onChange?.(newValue);
              field.onChange(newValue);
            }}
            options={availableOptions}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip {...getTagProps({ index })} key={option?.value} size="small" label={option.label} />
              ))
            }
            renderInput={(params) => {
              return (
                <TextField
                  label={
                    <>
                      {label}
                      {required ? <span className="text-primary-main">{' *'}</span> : ''}
                    </>
                  }
                  variant="outlined"
                  error={error}
                  helperText={error?.message}
                  {...params}
                  autoComplete="off"
                  // inputProps={{
                  //   ...inputProps,
                  //   ...params.inputProps,
                  //   // autoComplete: 'new-password',
                  //   'aria-autocomplete': 'none',
                  //   autoComplete: 'off',
                  //   endAdornment: [params?.InputProps?.endAdornment, InputProps?.endAdornment],
                  // }}
                  InputProps={{
                    // disabled: absolute,
                    ...inputProps,
                    ...params.InputProps,
                    ...InputProps,

                    inputProps: {
                      ...params.inputProps,

                      'aria-autocomplete': 'none',
                      autoComplete: 'off',
                    },

                    // inputProps: {
                    //   ...params?.InputProps?.inputProps,
                    //   ...inputProps?.inputProps,
                    // },
                    endAdornment: [params?.InputProps?.endAdornment, InputProps?.endAdornment],
                  }}
                />
              );
            }}
            // value={multiple ? field.value || [] : field.value || null}
            value={multiple ? field.value || defaultValue || [] : field.value || defaultValue || null}
            // defaultValue={field.value}
            renderOption={(props, option) => (
              <li
                {...props}
                style={{ pointerEvents: option.disabled ? 'none' : 'auto', opacity: option.disabled ? 0.3 : 1 }}
              >
                {option.label}
              </li>
            )}
            {...other}
          />
        );
      }}
    />
  );
};

export default RHFSelector;
