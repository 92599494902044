/* eslint-disable no-unreachable */
import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation, Routes, Route } from 'react-router-dom';

// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import PermissionRoute from '../permission/permissionRoute';
import {
  adminPermission,
  newsAgencyPermission,
  reportagePermission,
  rolePermission,
  sellerPermission,
  ticketPermission,
  transactionPermission,
} from '../permission';
import { PATH_DASHBOARD, PATH_AUTH } from './paths';
import RouterMiddleware from '../guards/RouterMiddleware';
import PublicFormComponent from '../pages/RegistrantForms/index';
import Registered from '../pages/Registered/index';

import LoginPublic from '../pages/public/auth/Login';
import RegisterPublic from '../pages/public/auth/Register';
import ResetPasswordPublic from '../pages/public/auth/ResetPassword';
import VerifyCodePublic from '../pages/public/auth/VerifyCode';
import Notifications from '../pages/notifications';
import Documents from '../pages/Documents';
import TravelHistory from '../pages/travelHistory/index';
import CaravanInfo from '../pages/caravanInfo';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router({ admin }) {
  // const routesArr = [
  //   {
  //     path: 'auth',
  //     children: [
  //       {
  //         path: 'login',
  //         element: (
  //           <GuestGuard>
  //             <Login />
  //           </GuestGuard>
  //         ),
  //       },
  //       {
  //         path: 'register',
  //         element: (
  //           <GuestGuard>
  //             <Register />
  //           </GuestGuard>
  //         ),
  //       },
  //       { path: 'login-unprotected', element: <Login /> },
  //       { path: 'register-unprotected', element: <Register /> },
  //       { path: 'reset-password', element: <ResetPassword /> },
  //       { path: 'verify', element: <VerifyCode /> },
  //     ],
  //   },
  // ];

  return useRoutes([
    // {
    //   path: 'auth',
    //   children: [
    //     {
    //       path: 'login',
    //       element: (
    //         <GuestGuard>
    //           <Login />
    //         </GuestGuard>
    //       ),
    //     },
    //     {
    //       path: 'register',
    //       element: (
    //         <GuestGuard>
    //           <Register />
    //         </GuestGuard>
    //       ),
    //     },
    //     { path: 'login-unprotected', element: <Login /> },
    //     { path: 'register-unprotected', element: <Register /> },
    //     { path: 'reset-password', element: <ResetPassword /> },
    //     { path: 'verify', element: <VerifyCode /> },
    //   ],
    // },
    {
      path: `${PATH_DASHBOARD.registered.root}`,
      element: <Registered />,
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard isPublic>
          <DashboardLayout isPublic />
        </AuthGuard>
      ),
      children: [
        // { element: <Navigate to={PATH_DASHBOARD.general.app} replace />, index: true },
        { path: '', element: <GeneralAppPublic /> },
        {
          path: `${PATH_DASHBOARD.form.root()}/*`,
          element: <PublicFormComponent />,
          // index: true,
        },
      ],
    },
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: PATH_AUTH.login,
          element: <LoginPublic />,
        },
        {
          path: PATH_AUTH.register,
          element: <RegisterPublic />,
        },
        { path: PATH_AUTH.loginUnprotected, element: <LoginPublic /> },
        { path: PATH_AUTH.registerUnprotected, element: <RegisterPublic /> },
        { path: PATH_AUTH.resetPassword, element: <ResetPasswordPublic /> },
        { path: PATH_AUTH.verify, element: <VerifyCodePublic /> },
      ],
    },
    // Dashboard admin Routes
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),

      children: [
        {
          path: `${PATH_DASHBOARD.document.root}/*`,
          // element: <Documents />,
          children: [
            {
              path: ':tab',
              element: <Documents />,
            },
            {
              path: '',
              element: <Documents />,
            },
          ],
          // permissions
        },
        {
          path: `${PATH_DASHBOARD.notificationSection.root}/*`,
          element: <Notifications />,
          // index: true,
        },
        {
          path: `${PATH_DASHBOARD.travelHistory.root}/*`,
          element: <TravelHistory />,
          // index: true,
        },
        {
          path: `${PATH_DASHBOARD.caravanInfo.root}/*`,
          element: <CaravanInfo />,
          // index: true,
        },

        // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: 'app', element: <GeneralApp /> },
        // {
        //   path: `${PATH_DASHBOARD.visitorManagement.root()}/*`,
        //   element: <VisitorManagement />,
        //   // permissions
        // },
        // {
        //   path: `${PATH_DASHBOARD.records.root()}/*`,
        //   element: <Records />,
        // },
        // {
        //   path: `${PATH_DASHBOARD.dutySystemManagement.root()}/*`,
        //   element: <DutySystemManagement />,
        // },
        // {
        //   path: `${PATH_DASHBOARD.bankSystemManagement.root()}/*`,
        //   element: <BankSystemManagement />,
        // },
        // {
        //   path: `${PATH_DASHBOARD.caravansManagement.root()}/*`,
        //   element: <CaravansManagement />,
        // },
        // {
        //   path: `${PATH_DASHBOARD.profile.root}/*`,
        //   element: <Profile />,
        // },
        // {
        //   path: `${PATH_DASHBOARD.forms.root()}/*`,
        //   element: <FormsComponent />,
        //   // index: true,
        // },
        // {
        //   path: `${PATH_DASHBOARD.agent.root}/*`,
        //   element: <Agents />,
        //   // index: true,
        // },
        // {
        //   path: `${PATH_DASHBOARD.universityCategory.root}/*`,
        //   element: <UniversityCategory />,
        //   // index: true,
        // },
        // {
        //   path: `${PATH_DASHBOARD.university.root}/*`,
        //   element: <University />,
        //   // index: true,
        // },
        // {
        //   path: `${PATH_DASHBOARD.messages.root}/*`,
        //   element: <Messages />,
        //   // index: true,
        // },

        // {
        //   path: `${PATH_DASHBOARD.form.root()}/*`,
        //   element: <FormComponent />,
        //   // index: true,
        // },
        // {
        //   path: 'game',
        //   children: [
        //     { element: <Navigate to="/game/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: <PermissionRoute element={<GamesList />} permissions={adminPermission.read} />,
        //     },
        //     {
        //       path: 'new',
        //       element: <PermissionRoute element={<CreateNewGame />} permissions={adminPermission.create} />,
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <PermissionRoute element={<CreateNewGame />} permissions={adminPermission.update} />,
        //     },
        //     // { path: 'post/:title', element: <BlogPost /> },
        //     // { path: 'new', element: <AdminCreate /> },
        //     // { path: 'edit/:id', element: <AdminCreate /> },
        //   ],
        // },
        // {
        //   path: 'ticket-department',
        //   children: [
        //     { element: <Navigate to="/ticket-department/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: <PermissionRoute element={<TicketDepartmentsList />} permissions={adminPermission.read} />,
        //     },
        //     {
        //       path: 'new',
        //       element: <PermissionRoute element={<CreateNewTicketDepartment />} permissions={adminPermission.create} />,
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <PermissionRoute element={<CreateNewTicketDepartment />} permissions={adminPermission.update} />,
        //     },
        //     // { path: 'post/:title', element: <BlogPost /> },
        //     // { path: 'new', element: <AdminCreate /> },
        //     // { path: 'edit/:id', element: <AdminCreate /> },
        //   ],
        // },
        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/blog/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: <PermissionRoute element={<BlogsList />} permissions={adminPermission.read} />,
        //     },
        //     {
        //       path: 'new',
        //       element: <PermissionRoute element={<CreateNewBlog />} permissions={adminPermission.create} />,
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <PermissionRoute element={<CreateNewBlog />} permissions={adminPermission.update} />,
        //     },
        //     // { path: 'post/:title', element: <BlogPost /> },
        //     // { path: 'new', element: <AdminCreate /> },
        //     // { path: 'edit/:id', element: <AdminCreate /> },
        //   ],
        // },
        // {
        //   path: 'category',
        //   children: [
        //     { element: <Navigate to="/category/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: <PermissionRoute element={<CategoriesList />} permissions={adminPermission.read} />,
        //     },
        //     {
        //       path: 'new',
        //       element: <PermissionRoute element={<CreateNewCategory />} permissions={adminPermission.create} />,
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <PermissionRoute element={<CreateNewCategory />} permissions={adminPermission.update} />,
        //     },
        //     // { path: 'post/:title', element: <BlogPost /> },
        //     // { path: 'new', element: <AdminCreate /> },
        //     // { path: 'edit/:id', element: <AdminCreate /> },
        //   ],
        // },
        // {
        //   path: 'record',
        //   children: [
        //     { element: <Navigate to="/record/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: <PermissionRoute element={<RecordsList />} permissions={adminPermission.read} />,
        //     },
        //     {
        //       path: 'new',
        //       element: <PermissionRoute element={<CreateNewRecord />} permissions={adminPermission.create} />,
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <PermissionRoute element={<CreateNewRecord />} permissions={adminPermission.update} />,
        //     },
        //     // { path: 'post/:title', element: <BlogPost /> },
        //     // { path: 'new', element: <AdminCreate /> },
        //     // { path: 'edit/:id', element: <AdminCreate /> },
        //   ],
        // },
        // {
        //   path: 'admin',
        //   children: [
        //     { element: <Navigate to="/admin/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: <PermissionRoute element={<AdminList />} permissions={adminPermission.read} />,
        //     },
        //     {
        //       path: 'new',
        //       element: <PermissionRoute element={<AdminCreate />} permissions={adminPermission.create} />,
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <PermissionRoute element={<AdminCreate />} permissions={adminPermission.update} />,
        //     },
        //     // { path: 'post/:title', element: <BlogPost /> },
        //     // { path: 'new', element: <AdminCreate /> },
        //     // { path: 'edit/:id', element: <AdminCreate /> },
        //   ],
        // },
        // {
        //   path: 'financial',
        //   children: [
        //     { element: <Navigate to="/financial/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: <PermissionRoute element={<TransactionList />} permissions={transactionPermission.read} />,
        //     },
        //     // { path: 'list', element: <TransactionList /> },
        //     // { path: 'post/:title', element: <BlogPost /> },
        //     // { path: 'new', element: <AdminCreate /> },
        //   ],
        // },
        // {
        //   path: 'role',
        //   children: [
        //     { element: <Navigate to="/role/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: <PermissionRoute element={<RoleList />} permissions={rolePermission.read} />,
        //     },
        //     {
        //       path: 'new',
        //       element: <PermissionRoute element={<CreateNewRole />} permissions={rolePermission.create} />,
        //     },
        //     {
        //       path: 'edit/:id',
        //       element: <PermissionRoute element={<CreateNewRole />} permissions={rolePermission.update} />,
        //     },
        //     // { path: 'list', element: <RoleList /> },
        //     // { path: 'post/:title', element: <BlogPost /> },
        //     // { path: 'new', element: <CreateNewRole /> },
        //     // { path: 'edit/:id', element: <CreateNewRole /> },
        //   ],
        // },

        // {
        //   path: 'ticket',
        //   children: [
        //     { element: <Navigate to="/ticket/list" replace />, index: true },
        //     {
        //       path: 'list',
        //       element: <PermissionRoute element={<TicketList />} permissions={ticketPermission.read} />,
        //     },
        //     // {
        //     //   path: 'new',
        //     //   element:
        //     //     <PermissionRoute
        //     //       element={<ShowTicketChat />}
        //     //       permissions={ticketPermission.create}
        //     //     />
        //     // },
        //     {
        //       path: 'edit/:id',
        //       element: <PermissionRoute element={<ShowTicketChat />} permissions={ticketPermission.update} />,
        //     },
        //     // { path: 'list', element: <TicketList /> },
        //     // { path: 'edit/:id', element: <ShowTicketChat /> },
        //   ],
        // },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    { path: '/', element: <Navigate to={PATH_AFTER_LOGIN} replace /> },

    // Main Routes
    {
      path: '*',
      element: (
        <RouterMiddleware>
          <LogoOnlyLayout />
        </RouterMiddleware>
      ),
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        {
          path: '*',
          element: <Navigate to="/404" replace />,
        },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    // {
    //   path: '*',
    //   element: (
    //     <RouterMiddleware>
    //       <Navigate to="/404" replace />
    //     </RouterMiddleware>
    //   ),
    // },
  ]);
}

// GENERAL
// const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralAppPublic = Loadable(lazy(() => import('../pages/dashboard/GeneralAppPublic')));

// // ADMIN
// const AdminList = Loadable(lazy(() => import('../pages/admin Management/AdminList')));
// const AdminCreate = Loadable(lazy(() => import('../pages/admin Management/AdminCreate')));

// // ROLE
// const RoleList = Loadable(lazy(() => import('../pages/role aa/list')));
// const CreateNewRole = Loadable(lazy(() => import('../pages/role aa/create')));

// // FINANCIAL
// const TransactionList = Loadable(lazy(() => import('../pages/financial/transactions')));
// // const AdminCreate = Loadable(lazy(() => import('../pages/admin Management/AdminCreate')));

// // TICKET
// const TicketList = Loadable(lazy(() => import('../pages/tickets/list')));
// const ShowTicketChat = Loadable(lazy(() => import('../pages/tickets/chat')));

// RECORD
// const RecordsList = Loadable(lazy(() => import('../pages/records/list')));
// const CreateNewRecord = Loadable(lazy(() => import('../pages/records/create')));

// // MANAGING DATA
// const GeneralVipDiscount = Loadable(lazy(() => import('../pages/generalVipDiscount')));

// // Country
// const CountryList = Loadable(lazy(() => import('../pages/country/list')));
// const CreateNewCountry = Loadable(lazy(() => import('../pages/country/create')));

// // Language
// const LanguageList = Loadable(lazy(() => import('../pages/language/list')));
// const CreateNewLanguage = Loadable(lazy(() => import('../pages/language/create')));

// // USER
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// // const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// MAIN

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
