import { formTypesObject } from '../../../../enumeration';
import FormInputText from './Text';
import FormInputNumber from './Number';
import FormInputRadio from './Radio';
import FormInputSelect from './Select';
import FormInputCheckbox from './Checkbox';
import FormInputImage from './Image';
import FormInputSocial from './Social';
import FormInputDate from './Date';
import FormInputString from './String';
import FormInputLocation from './Location';
import { Grid } from '@mui/material';

const InputsCore = ({ data, withGridBox =false ,gridSize = {} ,  ...props }) => {
  // console.log('* * * InputsCore : ', { data }, data?.type);

  const formHasGridMode = [
    formTypesObject.SELECT.value, 
    formTypesObject.DATE.value, 
    formTypesObject.STRING.value , 
    formTypesObject.NUMBER.value
  ];

  const componentByTypes = {
    [formTypesObject.NUMBER.value]: <FormInputNumber {...{ data, withGridBox , gridSize , ...props }} />,
    [formTypesObject.SELECT.value]: <FormInputSelect {...{ data, withGridBox , gridSize, ...props }} />,
    [formTypesObject.RADIO.value]: <FormInputRadio {...{ data, ...props }} />,
    [formTypesObject.CHECKBOX.value]: <FormInputCheckbox {...{ data, ...props }} />,
    [formTypesObject.SOCIAL_MEDIA.value]: <FormInputSocial {...{ data, ...props }} />,
    [formTypesObject.STRING.value]: <FormInputString {...{ data, withGridBox , gridSize , ...props }} />,
    [formTypesObject.code_melli.value]: <FormInputString {...{ data, ...props }} />,
    [formTypesObject.TEXT.value]: <FormInputText {...{ data, ...props }} />,
    [formTypesObject.IMAGE.value]: <FormInputImage {...{ data, ...props }} />,
    [formTypesObject.DATE.value]: <FormInputDate {...{ data,withGridBox , gridSize, ...props }} />,
    [formTypesObject.LOCATION.value]: <FormInputLocation {...{ data, ...props }} />,
    [formTypesObject.phone.value]: <FormInputString {...{ data, ...props }} />,
    [formTypesObject.code_melli.value]: <FormInputString {...{ data, ...props }} />,
    [formTypesObject.STRING.value]: <FormInputString {...{ data, withGridBox , gridSize , ...props }} />,
  };
  
  if(withGridBox && data?.type){
    return(
      formHasGridMode.indexOf(data?.type) > -1 ? componentByTypes[data?.type] || <></> : 
      <>
        <Grid item {...gridSize}>
          {componentByTypes[data?.type] || <></>}
        </Grid> 
      </>
    )
  }

  return data?.type ? componentByTypes[data?.type] || <></> : <></>;
};

export default InputsCore;
