/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
import { Grid, InputAdornment } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import RHFSelector from '../../../../components/hook-form/RHFSelector';
import AcceptOrRejectWithQuestionsWithReason from '../AcceptOrRejectWithQuestionsWithReason';

const FormInputSelect = ({ name, data, disabled, isHistory, index, withGridBox, gridSize }) => {
  // const [beforeValue,setBeforeValue]=useState()
  const [hidden, setHidden] = useState(false);
  const [FilterOptions, setFilterOptions] = useState([])

  const { control, watch, setValue, getValues } = useFormContext();

  const parentUniversityProvinceNames = index >= 0 ? data?.parentUniversityProvinceNames?.[index] : data?.parentUniversityProvinceName;
  const parentUniversityCategoryNames = index >= 0 ? data?.parentUniversityCategoryNames?.[index] : data?.parentUniversityCategoryName;

  let watchParent;
  let watchParent1;
  const watchProvince = watch(`${parentUniversityProvinceNames}`);
  const watchCategory = watch(`${parentUniversityCategoryNames}`);
  if (data?.options?.parentId) watchParent = watch(`${data?.options?.parentId}`);
  if (data?.options?.parentId1) watchParent1 = watch(`${data?.options?.parentId1}`);
  // console.log("* * * FormInputLocationCity : ",{name,data,watchProvince},`${data?.parentProvinceName}`);

  const options = useMemo(() => {
    return {
      // array: data?.options?.items?.map((x) => ({ label: x.label, value: x.label })),
      array: data?.options?.items
        ?.filter((x) =>
          !FilterOptions.some((word) => {
            if (Array.isArray(word)) {
              return word.some(subWord => x?.label?.includes(subWord));
            }
            return x?.label?.includes(word);
          })
        )
        ?.map((x) => ({ label: x.label, value: x.label })),
      data: data?.options,
      selector: data?.options?.selectType?.selector,
    };
  }, [data, FilterOptions]);

  const onChange = (e) => {
    console.log('* * * FormInputSelect', { e });
  };

  useEffect(() => {
    if (!watchParent && !watchParent1) return;
    const afterChangeParent = data?.options?.afterChangeParent;
    if (afterChangeParent) {
      const is = watchParent?.value === afterChangeParent.on || watchParent1?.value === afterChangeParent.on;
      // const showOn = watchParent?.value === afterChangeParent.showOn;
      if (afterChangeParent.by === 'child') {
        if (is && !afterChangeParent.showOn) {
          if (afterChangeParent.type === 'resetChilds') setValue(name, null);
          if (data?.options?.afterChangeParent?.withHidden && !hidden) setHidden(true);
        } else if (afterChangeParent.showOn) {

          if (is) setHidden(false)
          else { setHidden(true); setValue(name, null) }

        } else if (data?.options?.afterChangeParent?.withHidden && hidden) setHidden(false);
        else if (afterChangeParent.type === 'resetOptions') {
          let helpArr = [...FilterOptions];

          let findIndex = afterChangeParent.on?.indexOf(watchParent?.value)

          if (findIndex === -1) {
            findIndex = afterChangeParent.on?.indexOf(watchParent1?.value)
          }

          if (findIndex > -1) {
            if (helpArr[afterChangeParent?.filterIndex]) {
              helpArr[afterChangeParent?.filterIndex] = afterChangeParent?.removeWord[findIndex];
            } else {
              if (afterChangeParent?.filterIndex === 0) {
                helpArr.push(afterChangeParent?.removeWord[findIndex]);
              } else {
                helpArr.length = Math.max(helpArr.length, afterChangeParent?.filterIndex + 1);

                helpArr.fill(null, helpArr.length, afterChangeParent?.filterIndex);

                helpArr[afterChangeParent?.filterIndex] = afterChangeParent?.removeWord[findIndex];
              }
            }

            const optionsArr = data?.options?.items
              ?.filter((x) =>
                !helpArr.some((word) => {
                  if (Array.isArray(word)) {
                    return word.some(subWord => x?.label?.includes(subWord));
                  }
                  return x?.label?.includes(word);
                })
              )
              ?.map((x) => ({ label: x.label, value: x.label }))
            const findSelectedItemOpIndex = optionsArr?.findIndex(item => item?.value === getValues(name)?.value)
            if (findSelectedItemOpIndex === -1) setValue(name, null);
          } else {
            helpArr.length = 0;
          }

          if (afterChangeParent?.otherParentControll) {
            // console.log({
            //   hhhi: watchParent1?.value,
            //   hhhi1: afterChangeParent?.hiddenOn,
            //   con: (watchParent?.value === afterChangeParent?.hiddenOn),
            //   la: data?.label
            // })
            if (
              watchParent?.value === afterChangeParent?.otherParentControll ||
              watchParent1?.value === afterChangeParent?.otherParentControll
            ) {
              helpArr = afterChangeParent?.otherParentControllFilter;
              setValue(name, null);
            }
          }


          setFilterOptions(helpArr)
        }
        if (afterChangeParent?.hiddenOn) {
          console.log(data?.label, afterChangeParent?.hiddenItem)
          for (const key in afterChangeParent?.hiddenItem) {
            if (Object.prototype.hasOwnProperty.call(afterChangeParent?.hiddenItem, key)) {
              const element = afterChangeParent?.hiddenItem[key];
              console.log({
                hiddenItem: element,
                item: data,
                label: data?.label
              })
              if ((element?.input?.label?.trim() === data?.label) && (watchParent?.value === afterChangeParent?.hiddenOn || watchParent1?.value === afterChangeParent?.hiddenOn)) {
                setHidden(true)
                setValue(name, null);
              } else {
                setHidden(false)
              }
            }
          }
        }
      }
    }
  }, [watchParent, watchParent1]);

  console.log(
    { options, watchParent, watchParent1, data, index, name },
    data?.client_inputs?.[index || 0]?.value,
    data?.label,
    '* * * FormInputSelect'
  );

  if (hidden) return <></>;
  if (options.selector) {
    return (
      <>
        {withGridBox ?
          <Grid item {...gridSize}>
            <options.selector.Element
              geById={data?.client_inputs?.[index || 0]?.value}
              name={name}
              label={data?.label}
              provinceId={watchProvince?.value}
              categoryId={watchCategory?.value}
              disabled={disabled}
              required={data?.options?.required}
              InputProps={
                data?.need_confirm //&& isHistory
                  ? {
                    endAdornment: (
                      <InputAdornment
                        // onMouseDown={(event) => {
                        //   // event.preventDefault();
                        //   event.stopPropagation();
                        // }}
                        // onClick={(event) => {
                        //   event.preventDefault();
                        //   event.stopPropagation();
                        // }}
                        position="start"
                      >
                        <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[index || 0]} />
                      </InputAdornment>
                    ),
                  }
                  : ''
              }
            />
          </Grid>
          :
          <options.selector.Element
            geById={data?.client_inputs?.[index || 0]?.value}
            name={name}
            label={data?.label}
            disabled={disabled}
            required={data?.options?.required}
            provinceId={watchProvince?.value}
            categoryId={watchCategory?.value}
            InputProps={
              data?.need_confirm //&& isHistory
                ? {
                  endAdornment: (
                    <InputAdornment
                      // onMouseDown={(event) => {
                      //   // event.preventDefault();
                      //   event.stopPropagation();
                      // }}
                      // onClick={(event) => {
                      //   event.preventDefault();
                      //   event.stopPropagation();
                      // }}
                      position="start"
                    >
                      <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[index || 0]} />
                    </InputAdornment>
                  ),
                }
                : ''
            }
          />
        }

      </>
    );
  }
  return (

    <>
      {withGridBox ?
        <>
          <Grid item {...gridSize}>
            <RHFSelector
              options={options?.array || []}
              name={name}
              label={data?.label}
              disabled={disabled}
              onChange={onChange}
              InputProps={
                data?.need_confirm && isHistory
                  ? {
                    endAdornment: (
                      <InputAdornment
                        // onMouseDown={(event) => {
                        //   // event.preventDefault();
                        //   event.stopPropagation();
                        // }}
                        // onClick={(event) => {
                        //   event.preventDefault();
                        //   event.stopPropagation();
                        // }}
                        position="start"
                      >
                        <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[index || 0]} />
                      </InputAdornment>
                    ),
                  }
                  : ''
              }
            />
          </Grid>
        </>
        :

        <RHFSelector
          options={options?.array || []}
          name={name}
          label={data?.label}
          disabled={disabled}
          onChange={onChange}
          InputProps={
            data?.need_confirm && isHistory
              ? {
                endAdornment: (
                  <InputAdornment
                    // onMouseDown={(event) => {
                    //   // event.preventDefault();
                    //   event.stopPropagation();
                    // }}
                    // onClick={(event) => {
                    //   event.preventDefault();
                    //   event.stopPropagation();
                    // }}
                    position="start"
                  >
                    <AcceptOrRejectWithQuestionsWithReason {...data?.client_inputs?.[index || 0]} />
                  </InputAdornment>
                ),
              }
              : ''
          }
        />
      }
    </>
  );
};

export default FormInputSelect;
