import { useState, useRef, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Modal, Button, Typography, CircularProgress, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { CheckCircle, ControlPoint } from '@mui/icons-material';
import { yupResolver } from '@hookform/resolvers/yup';
import CaravanSelector from 'src/components/selector/Caravan';
import { isArray } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import axiosInstance from '../../../utils/axios';
import Enum, { optionsSelect } from '../enum';
import { useMutationCustom, useQueryCustom } from '../../../utils/reactQueryHooks';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import useSettings from '../../../hooks/useSettings';
import WaitingBox from '../../../components/WaitingBox/index';
import SectionPublic from '../Section';
import FormProvider from '../../../components/hook-form/FormProvider';
import { formTypesObject, travelTypeObject } from '../../../enumeration';
import errorsText from '../../../utils/errorsText';
import validation from './validation';
import api from '../../../services/api';
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
import persianToEnglishNumber from '../../../utils/persianToEnglishNumber';
import { fDateForApi } from '../../../utils/formatTime';
import ModalLayout from '../../../components/ModalLayout';
import QuestionComponent from '../../../components/ui/Question/index';
import CaravanInput from '../Section/InputsCore/Caravan';
import RegistrantForm from '../components/Form';
import useAuth from 'src/hooks/useAuth';

const ById = () => {
  const { isAuthenticated } = useAuth();
  // ------------------------------------------------------------------------------ modal ref
  const queryParams = useParams();
  const { id, travel } = queryParams;
  const { t } = useTranslation();
  const navigate = useNavigate();
  // ------------------------------------------------------------------------------ modal toggle

  // ------------------------------------------------------------------------------ Mutation service
  // ----------------------------------------------------------------------------- SERVICE
  const creating = (params) => axiosInstance.post(api.public.travelRegister.base, params);
  const updating = (params) => axiosInstance.post(`${api.public.travelRegister.base}/${id}`, params);
  const getById = async ({ queryKey }) => {
    const [_, params] = queryKey || [];
    return axiosInstance.get(`${Enum?.api?.base}/${id}`);
  };
  // const getById = () => axiosInstance.get(`${api.input.base}/${data}`);
  // ------------------------------------------------------------------------------ Mutation
  // const onSuccessMutating = () => {
  //   // toast.success(t('successfully'));

  //   // reset({

  //   // });
  //   handleSuccessModal();
  //   // navigate( PATH_DASHBOARD.registered.root)
  // };

  // const onErrorMutating = (error) => {
  //   console.log({ error });
  //   const errorTitle = error.response.data.message || t('errorTryAgain');

  //   const errors = Object.values(error?.response?.data?.errors || {});
  //   if (errors?.length) {
  //     errors?.map((x) => {
  //       return toast.error(x?.[0]);
  //     });
  //   } else toast.error(errorTitle);
  // };

  // const { isLoading, mutate } = useMutationCustom({
  //   url: beforeRegistrant ? updating : creating,
  //   name: `${api.public.travelRegister.base}_add`,
  //   // invalidQuery: `${Enum?.api?.base}_get_${id}_${travel}`,
  //   onSuccess: onSuccessMutating,
  //  //  onError: onErrorMutating,
  // });
  // -------
  // ------------------------------------------------------------------------------ handler

  // const persianNumber = 'asdad۱۲۳۴۵۶';
  // const englishNumber = persianToEnglishNumber(persianNumber);
  // console.log({englishNumber});
  // const getting = async () =>
  //   axiosInstance.get(api.section.base, {
  //     params: {
  //       form_id: id,
  //     },
  //   });

  const resQuery = useQueryCustom({
    name: `${Enum?.api?.base}_get_${id}_${travel}`,
    url: getById,
    params: { id },
    // onSuccess: onSuccess,
  });
  // useEffect(()=>{
  //   handleSuccessModal()
  // },[ModalLayoutSuccessRef])

  // const sectionsQuery = useQueryCustom({
  //   name: `${api.section.base}_get_${id}`,
  //   url: getting,
  // });

  console.log({ resQuery, travel });

  const handleSuccess = () => {
    if (isAuthenticated) resQuery.refetch();
  };
  const handleSuccessFinally = () => {
    if (!isAuthenticated) navigate(PATH_AUTH.login);
  };

  return (
    <Box
      sx={
        {
          // py:3
        }
      }
    >
      {resQuery.isLoading ? (
        <WaitingBox />
      ) : resQuery.isError ? (
        ''
      ) : (
        <RegistrantForm data={resQuery?.data?.data} refetch={handleSuccess} completed={handleSuccessFinally} />
      )}
    </Box>
  );
};

export default ById;
